import React, { useState, useEffect, useMemo, useRef } from "react";
import { getSymbols } from "../../services/SymbolsService";
import SelectQuote, { getDefaultQuote } from "../SelectQuote/SelectQuote";

/**
 * props:
 * - symbol
 * - onChange 
 * - onlyFavorites
 * - disabled
 * - showAny
 */
function SelectSymbol(props) {

    const [quote, setQuote] = useState(false);
    const [symbols, setSymbols] = useState(["LOADING"]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [onlyFavorites, setOnlyFavorites] = useState(props.onlyFavorites === null || props.onlyFavorites === undefined ? true : props.onlyFavorites);

    const selectRef = useRef("");
    const buttonRef = useRef("");

    function onFavoriteClick(event) {
        setOnlyFavorites(!onlyFavorites);
    }

    function getStarFillCollor() {
        return onlyFavorites ? "yellow" : "white";
    }

    useEffect(() => {
        if(!props.symbol || selectRef.current.value === props.symbol) return;

        const isWildcard = props.symbol.startsWith('*');

        selectRef.current.value = isWildcard ? '*' : props.symbol;
        setQuote(isWildcard ? props.symbol.replace('*', '') : false);
    }, [props.symbol])

    useEffect(() => {
        buttonRef.current.disabled = selectRef.current.disabled = props.disabled;
        setIsDisabled(props.disabled);
    }, [props.disabled])

    useEffect(() => {
        const token = localStorage.getItem("token");
        getSymbols(token)
            .then(symbolObjects => {
                const symbolNames = onlyFavorites
                    ? symbolObjects.filter(s => s.isFavorite).map(s => s.symbol)
                    : symbolObjects.map(s => s.symbol);

                if (onlyFavorites && !symbolNames.length)
                    setOnlyFavorites(false);

                if (symbolNames.length) {
                    setSymbols(symbolNames);
                    selectRef.current.value = symbolNames[0];
                    props.onChange({ target: { id: "symbol", value: symbolNames[0] } })
                }
                else setSymbols(["NO SYMBOLS"]);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setSymbols(["ERROR"]);
            })
    }, [onlyFavorites]);

    function onSymbolChange(event) {
        if (event.target.value !== '*') {
            setQuote(false);
            props.onChange(event);
        }
        else {
            setQuote(true);
            const newQuote = getDefaultQuote();
            props.onChange({ target: { id: 'symbol', value: '*' + newQuote } });
        }
    }

    function onQuoteChange(event) {
        props.onChange({ target: { id: 'symbol', value: '*' + event.target.value } });
    }

    const selectSymbol = useMemo(() => (
        <div className="input-group">
            <button ref={buttonRef} type="button" className="btn btn-secondary d-inline-flex align-items-center" onClick={onFavoriteClick}>
                <svg className="icon icon-xs" fill={getStarFillCollor()} stroke="orange" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" onClick={onFavoriteClick} >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>
                </svg>
            </button>
            <select ref={selectRef} id="symbol" className="form-select" onChange={onSymbolChange}>
                <option value="">Select...</option>
                {
                    props.showAny
                        ? <option value="*">Any</option>
                        : <React.Fragment></React.Fragment>
                }
                {symbols.map(s => (<option key={s} value={s}>{s}</option>))}
            </select>
            {
                quote
                    ? <SelectQuote onChange={onQuoteChange} value={quote} disabled={isDisabled} noFavorites={true} />
                    : <React.Fragment></React.Fragment>
            }
        </div>
    ), [symbols, quote, isDisabled])

    return (
        selectSymbol
    )
}

export default SelectSymbol;