import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const BEHOLDER_URL = `${API_URL}/beholder/`;

export async function getIndexes(token) {
    const beholderUrl = `${BEHOLDER_URL}memory/indexes`;
    const headers = { 'authorization': token }
    const response = await axios.get(beholderUrl, { headers });
    return response.data;
}

export async function getAnalysisIndexes(token) {
    const analysisUrl = `${BEHOLDER_URL}analysis`;
    const headers = { 'authorization': token }
    const response = await axios.get(analysisUrl, { headers });
    return response.data;
}

export async function getMemoryIndex(symbol, index, interval, token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${BEHOLDER_URL}memory/${symbol}/${index}/${interval ? interval : ''}`, { headers });
    return response.data;
}

export async function getMemory(token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${BEHOLDER_URL}memory/`, { headers });
    return response.data;
}

export async function getAgenda(token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${BEHOLDER_URL}agenda/`, { headers });
    return response.data;
}

export async function getBrain(token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${BEHOLDER_URL}brain/`, { headers });
    return response.data;
}
