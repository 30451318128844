import React, { useState, useEffect, useRef } from "react";
import { updateSymbol } from "../../services/SymbolsService";

/**
 * props:
 * - data
 */
function SymbolModal(props) {

    const btnClose = useRef('');
    const [symbol, setSymbol] = useState({});
    const [error, setError] = useState('');

    useEffect(() => {
        if (!props.data) return;
        setSymbol(props.data);
    }, [props.data])

    function onInputChange(event) {
        setSymbol(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    function getStarFillCollor() {
        return symbol.isFavorite ? "yellow" : "white";
    }

    function onFavoriteClick(event) {
        setSymbol(prevState => ({ ...prevState, isFavorite: !symbol.isFavorite }));
    }

    function onFavoriteClick(event) {
        symbol.isFavorite = !symbol.isFavorite;
        const token = localStorage.getItem('token');
        updateSymbol(symbol, token)
            .then(result => {
                setError('');
                setSymbol({ ...symbol });
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            });
    }

    return (
        <div className="modal fade" id="modalSymbol" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" >
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">View Symbol</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="py-3">
                            <div className="form-group mb-4">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="form-group mb-4">
                                            <label htmlFor="symbol">Symbol</label>
                                            <div className="input-group">
                                                <input className="form-control" id="symbol" type="text" placeholder="BTCUSDT" defaultValue={symbol.symbol} required disabled />
                                                <button type="button" className="btn btn-secondary d-inline-flex align-items-center me-2" onClick={onFavoriteClick} >
                                                    <svg className="icon icon-xs" fill={getStarFillCollor()} stroke="orange" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" onClick={onFavoriteClick} >
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Base Precision: </b>{symbol.basePrecision}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Quote Precision: </b>{symbol.quotePrecision}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Min. Notional: </b>{symbol.minNotional}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Min. Lot Size: </b>{symbol.minLotSize}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Tick Size: </b>{symbol.tickSize}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Step Size: </b>{symbol.stepSize}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        error
                            ? <div className="alert alert-danger">{error}</div>
                            : <React.Fragment></React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default SymbolModal;