import axios from './BaseService';
import { getDefaultFiat } from '../components/SelectFiat/SelecFiat';

const EXCHANGE_URL = `${process.env.REACT_APP_API_URL}/exchange/`;

export const STOP_TYPES = ['STOP_LOSS', 'STOP_LOSS_LIMIT', 'TAKE_PROFIT', 'TAKE_PROFIT_LIMIT'];

export async function getBalance(token) {
    const balanceUrl = `${EXCHANGE_URL}balance/${getDefaultFiat()}`;
    const headers = {
        'authorization': token
    }
    const response = await axios.get(balanceUrl, { headers });
    return response.data;
}

export async function getFullBalance(fiat, token) {
    const balanceUrl = `${EXCHANGE_URL}balance/full/${fiat}`;
    const headers = {
        'authorization': token
    }
    const response = await axios.get(balanceUrl, { headers });
    return response.data;
}

export async function getCoins(token) {
    const headers = { 'authorization': token };
    const response = await axios.get(EXCHANGE_URL + 'coins', { headers });
    return response.data;
}

export async function doWithdraw(withdrawTemplateId, token) {
    const headers = { 'authorization': token };
    const response = await axios.post(`${EXCHANGE_URL}withdraw/${withdrawTemplateId}`, null, { headers });
    return response.data;
}

